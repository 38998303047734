<template>
    <b-card class="mb-2" style="background: whitesmoke">
        <!-- show items -->
        <b-row cols="1" cols-sm="2" cols-md="3" cols-lg="">
            <b-col class="p-2" v-for="(item, key) in data" v-if="isItemNormal(item, key)">
                <div class="border">
                    <b-row no-gutters align-v="center">
                        <b-col cols="3" class="text-right">
                            {{$tc(key, 1) | capitalize}}:
                        </b-col>
                        <b-col cols="9" class="pl-1 font-weight-bold">
                            {{formatItem(item)}}
                        </b-col>
                    </b-row>
                </div>
            </b-col>
        </b-row>

        <!-- show meta -->
        <div v-if="data.meta != null" class="title">{{$tc('form.meta.data', 0)}}</div>
        <b-row cols="1" cols-sm="2" cols-md="3" cols-lg="">
            <b-col class="p-2" v-for="(item, key) in data.meta">
                <div class="border">
                    <b-row no-gutters align-v="center">
                        <b-col cols="3" class="text-right">
                            {{$tc(key, 1) | capitalize}}:
                        </b-col>
                        <b-col cols="9" class="pl-1 font-weight-bold">
                            {{formatItem(item)}}
                        </b-col>
                    </b-row>
                </div>
            </b-col>
        </b-row>

        <!-- show content -->
        <div v-if="data.content != null" class="title">{{$t('form.content')}}</div>
        <b-row cols="1" cols-sm="2" cols-md="3" cols-lg="">
            <b-col class="p-2" v-for="(item, key) in data.content">
                <div class="border">
                    <b-row no-gutters align-v="center">
                        <b-col cols="3" class="text-right">
                            {{$tc(key, 1) | capitalize}}:
                        </b-col>
                        <b-col cols="9" class="pl-1 font-weight-bold">
                            {{formatItem(item)}}
                        </b-col>
                    </b-row>
                </div>
            </b-col>
        </b-row>

        <!-- show locale, foreign keys values -->
        <div v-if="localeVisible == true && data.locale != null" class="title">{{$t('form.locale.locale')}}</div>
        <b-card v-if="localeVisible == true && data.locale != null" no-body>
            <b-tabs pills card style="background: #F0F0F0">
                <b-tab :title="key" :active="key === $i18n.locale" v-for="(locale, key, index) in data.locale"
                       style="background: whitesmoke">
                    <!-- locale -->
                    <b-row cols="1" cols-sm="2" cols-md="3" cols-lg="">
                        <b-col class="p-2" v-for="(item, key) in data.locale[key]" v-if="key !== 'notes'">
                            <div class="border">
                                <b-row no-gutters align-v="center">
                                    <b-col cols="3" class="text-right">
                                        {{$tc(key,1) | capitalize}}:
                                    </b-col>
                                    <b-col cols="9" class="pl-1 font-weight-bold">
                                        {{formatItem(item)}}
                                    </b-col>
                                </b-row>
                            </div>
                        </b-col>
                        <!-- foreign keys values -->
                        <b-col class="p-2" v-for="(item1, key1) in getForeignKeyData">
                            <div class="border">
                                <b-row no-gutters align-v="center">
                                    <b-col cols="3" class="text-right">
                                        {{$tc(key1, 1) | capitalize}}:
                                    </b-col>
                                    <b-col cols="9" class="pl-1 font-weight-bold">
                                        {{formatItem(item1[key])}}
                                    </b-col>
                                </b-row>
                            </div>
                        </b-col>
                    </b-row>
                    <!-- notes -->
                    <div v-if="data.locale[key].notes == null || !data.locale[key].notes.length > 0">
                        <b-row cols="1" cols-sm="2" cols-md="3" cols-lg="">
                            <b-col class="p-2">
                                <div class="border">
                                    <b-row no-gutters align-v="center">
                                        <b-col cols="3" class="text-right">
                                            {{$t('form.notes')}}:
                                        </b-col>
                                        <b-col cols="9" class="pl-1 font-weight-bold">
                                            /
                                        </b-col>
                                    </b-row>
                                </div>
                            </b-col>
                        </b-row>
                    </div>
                    <div v-else>
                        <label class="mb-0">{{$t('form.notes')}}:</label>
                        <div class="border" v-html="data.locale[key].notes"></div>
                    </div>
                </b-tab>
            </b-tabs>
        </b-card>
    </b-card>
</template>

<script>
    import utilsGeneral from "../../../utils/utilsGeneral";

    export default {
        name: "TableDetail",
        components: {},
        props: {
            data: {},
            columns: {},
            settings: {},
        },
        data() {
            return {
                localeVisible: false,
            }
        },
        mounted() {
            if(this.settings === undefined){
                this.localeVisible = true;
                return;
            }
            if( this.settings.localeVisible !== undefined){
                this.localeVisible = this.settings.localeVisible;
            }
        },
        methods: {
            formatItem(item) {
                if (item == null || item === undefined) {
                    return '/';
                }
                if (typeof item === 'boolean') {
                    return utilsGeneral.capitalize(this.$t(item.toString()));
                }
                if (typeof item === 'string' && !item.length > 0) {
                    return '/';
                }
                // <--  -->
                return item;
            },
            isItemNormal(item, key) {
                if (item instanceof Object) {
                    return false;
                }
                if (this.isItemForeignKey(key)) {
                    return false;
                }
                // <--  -->
                return true;
            },
            isItemForeignKey(key) {
                if (key.substr(key.length - 3) === '_id') {
                    return true;
                }

                return false;
            },
        },
        computed: {
            getForeignKeyData() {
                let data = {};
                for (let key in this.data) {
                    if (this.isItemForeignKey(key)) {
                        let column = key.substr(0, key.length - 3);
                        if (this.data[column] != null) {
                            let values = {};
                            if (this.data[column].locale != undefined) {
                                Object.keys(this.data[column].locale).forEach((value, index) => {
                                    values[value] = this.data[column].locale[value].name;
                                });
                                data[column] = values;
                            } else {
                                data[column] = {};
                            }
                        } else {
                            data[column] = {};
                        }
                    }
                }
                return data;
            }
        },
        filters: {},
        watch: {}
    }
</script>

<style scoped>
    .title {
        padding-top: 5px;
        font-weight: bold;
        color: gray;
    }
</style>
