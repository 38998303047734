<template>
    <div class="mb-2 d-flex justify-content-between align-items-center">
        <!-- title, rowsCount -->
        <div class="d-flex align-items-center font-weight-bold font-weight-bold">
            <span style="font-size: xx-large">{{title | capitalize}}</span>
            <span class="pl-2 pt-2" style="font-size: medium; font-weight: normal">({{$t('table.hits')}}: {{options.rowsCount}}, {{$t('table.shown')}}: {{options.filterCount}})</span>
        </div>
        <!-- tools -->
        <div class="d-flex align-items-sm-end">
            <!-- columnsShow -->
            <b-dropdown v-if="columnsShow.length > 0"
                        id="dropdown-table-columns-show"
                        :text="$t('table.select_columns')"
                        size="sm"
                        ref="dropdownTableColumns"
                        class="m-0 mr-2"
            >
                <!--                <div :style="{width: cWidth}">-->
                <b-form-checkbox-group
                        @input="setResponseColumnShow"
                        v-model="selectedColumnsShow"
                        class="mb-0 pl-3 pt-2 pb-1"
                        size="sm"
                        :options="columnsShow"
                        value-field="key"
                        text-field="label"
                        stacked
                ></b-form-checkbox-group>

                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-header id="dropdown-scope-header">
                    <b-button variant="link" size="sm" style="float: left" @click="selectColumnShowAll">
                        {{$t('table.select_all')}}
                    </b-button>
                    <b-button variant="link" size="sm" style="float: right" @click="clearColumnShowAll">
                        {{$t('table.clean_all') }}
                    </b-button>
                </b-dropdown-header>
            </b-dropdown>
            <!-- filter -->
            <FilterInput
                    v-if="filterVisible"
                    class="mr-2"
                    :settings="filterSettings"
                    :response.sync="response"
            ></FilterInput>
            <!-- per page -->
            <b-form-select
                    style="width: 65px"
                    class="mr-2"
                    size="sm"
                    v-model="options.pagination.perPage"
                    :options="paginationPerPage"
            ></b-form-select>
            <!-- button refresh -->
            <b-button
                    class="mr-2"
                    size="sm"
                    variant="info"
                    v-b-tooltip.hover
                    :title="$t('form.refresh')"
                    @click="baseSelect()"
            >
                <b-icon icon="arrow-repeat" scale="1.5" variant="light"></b-icon>
            </b-button>
            <!-- button new -->
            <b-button
                    size="sm"
                    variant="info"
                    v-b-tooltip.hover
                    :title="$t('form.add')"
                    @click="recordNew()"
            >
                <b-icon icon="plus" scale="2.3" variant="light"></b-icon>
            </b-button>
        </div>
    </div>
</template>

<script>
    import FilterInput from "@/components/general/elements/FilterInput_deprecated";

    export default {
        name: "TitleToolbar",
        components: {FilterInput},
        props: {
            settings: {},
            options: {
                type: Object,
                default() {
                    return {
                        rowsCount: -99,
                        filterCount: 0,
                        pagination: {
                            perPage: 10,
                            currentPage: 1,
                        },
                    }
                }
            },
            data: {
                type: Object,
                default() {
                    return {}
                    // scope: [
                    //     // {key: 'name.slo', label: 'Ime'},
                    //     // {key: 'name.slo_full', label: 'Polno ime'}
                    // ]
                }
            },
            response: {
                type: Object,
                default() {
                    return {
                        // search: '',
                        // columnOn: [],
                    }
                }
            },
            bus: Object,
        },
        data() {
            return {
                title: 'toolbarTitle',
                filterVisible: true,
                paginationPerPage: [
                    {value: '5', text: '5'},
                    {value: '10', text: '10'},
                    {value: '15', text: '15'},
                    {value: '20', text: '20'},
                    {value: '50', text: '50'},
                    {value: '100', text: '100'},
                    {value: '200', text: '200'},
                ],
                filterSettings: {
                    // width: '300px',
                    // scope[],
                },
                // columnsShow: [],
                columnsShow: [
                    // {label: 'Item 1', key: 'first', disabled: false},
                ],
                selectedColumnsShow: [],
            }
        },
        mounted() {
            this.setSettings();
        },
        methods: {
            setSettings() {
                if (this.settings == null) {
                    return;
                }
                // <-- title -->
                if (this.settings.title != null || this.settings.title !== undefined) {
                    this.title = this.settings.title;
                }
                // <-- filterVisible -->
                if (this.settings.filterVisible != null || this.settings.filterVisible !== undefined) {
                    this.filterVisible = this.settings.filterVisible;
                }
                // <-- paginationPerPage -->
                if (this.settings.paginationPerPage != null || this.settings.paginationPerPage !== undefined) {
                    this.paginationPerPage = this.settings.paginationPerPage;
                }
                // <-- filter -->
                if (this.settings.filter != null || this.settings.filter !== undefined) {
                    this.$set(this, 'filterSettings', this.settings.filter);
                }
            },
            // <-- buttons event -->
            baseSelect() {
                this.bus.$emit('baseSelect');
            },
            recordNew() {
                this.bus.$emit('recordNew');
            },
            setColumnsShow() {
                let array = [];
                let arraySelected = [];

                this.response.columns.forEach((value, index) => {
                    if (value.show != null) {
                        // <-- columns -->
                        array.push(
                            {
                                key: value.key,
                                label: value.label
                            }
                        );
                        // <-- columns show -->
                        if (value.show) {
                            arraySelected.push(
                                value.key
                            )
                        }
                    }
                });
                // <--  -->
                this.$set(this, 'selectedColumnsShow', arraySelected);
                this.$set(this, 'columnsShow', array);
            },
            selectColumnShowAll() {
                let arraySelected = [];
                this.columnsShow.forEach((value, index) => {
                    arraySelected.push(
                        value.key
                    )
                });
                // <--  -->
                this.$set(this, 'selectedColumnsShow', arraySelected);
            },
            clearColumnShowAll() {
                this.selectedColumnsShow = [];
            },
            setResponseColumnShow() {
                this.response.columns.forEach((value, index) => {
                    this.$set(this.response.columns[index], 'show', this.selectedColumnsShow.includes(value.key));
                });
            },
        },
        computed: {},
        filters: {},
        watch: {
            'response.columns': {
                handler: function (value, oldValue) {
                    if (oldValue === undefined) {
                        this.setColumnsShow();
                    }
                },
                deep: true
            },
        }
    }
</script>

<style scoped>
    .dropdown-header {
        padding: 5px !important;
        min-width: 180px !important;
    }

    .dropdown-divider {
        margin: 0 0 !important;
    }
</style>
