<template>
    <div :style="{width: cWidth}">
        <b-form-group
            class="mb-0"
            label-align-sm="right"
            label-cols-sm="0"
            label-for="filterInput"
            label-size="sm"
        >
            <b-input-group :style="{width: cWidth}" size="sm">
                <b-form-input
                    id="filterInput"
                    v-model="response.filter.search"
                    :placeholder="$t('search.search')"
                    type="search"
                ></b-form-input>
                <b-input-group-append v-if="scope.length > 0">
                    <b-dropdown
                        id="dropdown-scope"
                        no-caret
                        offset="1"
                        right
                        size="sm"
                        text="..."
                    >
                        <div :style="{width: cWidth}">
                            <b-dropdown-header id="dropdown-scope-header">
                                {{$t('search.limits')}}
                            </b-dropdown-header>
                            <b-dropdown-divider></b-dropdown-divider>
                            <b-form-checkbox-group
                                v-model="response.filter.columnOn"
                                :options="scope"
                                class="mb-0 pl-3 pt-2 pb-1"
                                stacked
                                text-field="label"
                                value-field="key"
                            ></b-form-checkbox-group>
                            <b-dropdown-divider></b-dropdown-divider>
                            <b-dropdown-header id="dropdown-scope-header">
                                <b-button size="sm" style="float: left" variant="link" @click="selectFilterOnAll">
                                    {{$t('table.select_all')}}
                                </b-button>
                                <b-button size="sm" style="float: right" variant="link" @click="clearFilterOnAll">
                                    {{$t('table.clean_all')}}
                                </b-button>
                            </b-dropdown-header>
                        </div>
                    </b-dropdown>
                </b-input-group-append>
            </b-input-group>
        </b-form-group>
    </div>
</template>

<script>

import utilsGeneral from "@/utils/utilsGeneral";

export default {
    name: "FilterInput",
    components: {},
    props: {
        settings: {},
        response: {}
    },
    data() {
        return {
            cWidth: '250px',
            scope: [
                // {key: 'name.slo', label: 'Ime'},
                // {key: 'name.slo_full', label: 'Polno ime'}
            ],
        }
    },
    mounted() {
        this.setSettings();
    },
    methods: {
        setSettings() {
            if (this.settings == null) {
                return;
            }
            // <-- width -->
            if (utilsGeneral.isNull(this.settings.width)) {
                this.$set(this, 'cWidth', this.settings.width);
            }
            // <-- scope -->
            if (utilsGeneral.isNull(this.settings.scope)) {
                this.$set(this, 'scope', this.settings.scope);
            }
        },
        selectFilterOnAll() {
            this.response.filter.columnOn = [];
            this.scope.forEach(value => {
                this.response.filter.columnOn.push(value.key);
            });
        },
        clearFilterOnAll() {
            this.response.filter.columnOn = [];
        }
    },
    computed: {},
    filters: {},
    watch: {
        'settings': {
            handler: function () {
                this.setSettings();
            },
            deep: true
        }
    }
}
</script>

<style scoped>
/*.dropdown-header {*/
/*    padding: 5px !important;*/
/*}*/

/*.dropdown-divider {*/
/*    margin: 0 0 !important;*/
/*}*/
</style>
