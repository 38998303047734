<template>
    <div>
        <b-form ref="form" @submit.prevent="submit">
            <!-- id, active -->
            <b-form-group
                class="mb-0"
                label-cols="6"
                :label="record.id === 0 ? '' : $t('form.id_value', {value: record.id})"
                label-size=""
                label-class="pt-0"
            >
                <b-form-checkbox
                    id="active"
                    class="float-right"
                    switch
                    v-model="record.active"
                    name="active"
                    value="true"
                    unchecked-value="false"
                >
                    {{activeText}}
                </b-form-checkbox>
            </b-form-group>
            <!-- meta -->
            <b-form-group
                :label="$tc('form.meta.data', 0)"
                class="mb-0 group-title p-0"
                label-class="font-weight-bold"
                label-align-sm="left"
                label-cols-sm="12"
                label-size=""
            >
                <b-form-row>
                    <!-- name -->
                    <b-form-group
                        id="group-meta.name"
                        class="col-sm-12 col-md-6"
                        label-class="mb-0"
                        :label="$t('form.name')"
                        label-for="meta.name"
                        description=""
                        :state="valid_metaName"
                    >
                        <b-form-input
                            id="meta.name"
                            v-model="record.meta.name"
                            :state="valid_metaName"
                            required
                            :placeholder="$t('form.required_field')"
                        ></b-form-input>
                    </b-form-group>
                    <!-- name_full -->
                    <b-form-group
                        id="group-meta.name_full"
                        class="col-sm-12 col-md-6"
                        label-class="mb-0"
                        :label="$t('form.name_full') "
                        label-for="meta.name_full"
                        description=""
                        :state="valid_metaNameFull"
                    >
                        <b-form-input
                            id="meta.name_full"
                            :state="valid_metaNameFull"
                            v-model="record.meta.name_full"
                            required
                            :placeholder="$t('form.required_field')"
                        ></b-form-input>
                    </b-form-group>
                </b-form-row>
            </b-form-group>
            <!-- content -->
            <b-form-group
                :label="$t('form.content')"
                class="mb-0 group-title p-0"
                label-class="font-weight-bold"
                label-align-sm="left"
                label-cols-sm="12"
                label-size=""
            >
                <b-form-row>
                    <!-- code -->
                    <b-form-group
                        id="group-content.code"
                        class="col-sm-6 col-md-3"
                        label-class="mb-0"
                        :label="$t('register.code')"
                        label-for="content.code"
                        description=""
                    >
                        <b-form-input
                            id="content.code"
                            v-model="record.content.code"
                        ></b-form-input>
                    </b-form-group>
                    <!-- domain -->
                    <b-form-group
                        id="group-content.domain"
                        class="col-sm-6 col-md-3"
                        label-class="mb-0"
                        :label="$t('register.domain')"
                        label-for="content.domain"
                        description=""
                    >
                        <b-form-input
                            id="content.domain"
                            v-model="record.content.domain"
                        ></b-form-input>
                    </b-form-group>
                    <!-- mark_2 -->
                    <b-form-group
                        id="group-content.mark_2"
                        class="col-sm-6 col-md-3"
                        label-class="mb-0"
                        :label="$t('register.mark_2')"
                        label-for="content.mark_2"
                        description=""
                    >
                        <b-form-input
                            id="content.mark_2"
                            v-model="record.content.mark_2"
                        ></b-form-input>
                    </b-form-group>
                    <!-- mark_3 -->
                    <b-form-group
                        id="group-content.mark_3"
                        class="col-sm-6 col-md-3"
                        label-class="mb-0"
                        :label="$t('register.mark_3')"
                        label-for="content.mark_3"
                        description=""
                    >
                        <b-form-input
                            id="content.mark_3"
                            v-model="record.content.mark_3"
                        ></b-form-input>
                    </b-form-group>
                    <!-- order -->
                    <b-form-group
                        id="group-order"
                        class="col-sm-6 col-md-3"
                        label-class="mb-0"
                        :label="$t('form.order')"
                        label-for="order"
                        description=""
                    >
                        <b-form-input
                            id="order"
                            type="number"
                            v-model="record.order"
                        ></b-form-input>
                    </b-form-group>
                    <!-- eu_member -->
                    <b-form-group
                        id="group-content.eu_member"
                        class="col-sm-6 col-md-3"
                        label-class="mb-0"
                        description=""
                    >
                        <b-form-checkbox
                            id="content.eu_member"
                            class="pt-4"
                            v-model="record.content.eu_member"
                            value="true"
                            unchecked-value="false"
                        >
                            {{$t('register.eu_member')}}
                        </b-form-checkbox>
                    </b-form-group>
                </b-form-row>
            </b-form-group>
            <!-- foreign_data -->
            <b-form-group
                class="mb-0 group-title p-0"
            >
                <b-form-row>
                    <!-- continent -->
                    <b-form-group
                        id="group-continent_id"
                        class="col-sm-12 col-md-6"
                        label-class="mb-0"
                        :label="$tc('register.continent', 1)"
                        label-for="continent_id"
                        description=""
                        :state="valid_continent"
                    >
                        <b-form-select
                            id="continent_id"
                            v-model="record.continent_id"
                            :options="continents"
                            required
                            :state="valid_continent"
                        >
                        </b-form-select>
                    </b-form-group>
                    <!-- currency -->
                    <b-form-group
                        id="group-currencies-id"
                        class="col-sm-12 col-md-6"
                        label-class="mb-0"
                        :label="$tc('register.currency', 1)"
                        label-for="currencies_id"
                        description=""
                        :state="valid_currency"
                    >
                        <b-form-select
                            id="currencies_id"
                            v-model="record.currency_id"
                            :options="currencies"
                        >
                        </b-form-select>
                        <b-form-invalid-feedback :state="valid_currency">
                            {{$t('form.recommended')}}
                        </b-form-invalid-feedback>
                    </b-form-group>
                </b-form-row>
            </b-form-group>
            <!-- locale-->
            <b-form-group
                :label="$t('form.locale.locale')"
                class="mb-0 group-title p-0"
                label-class="font-weight-bold"
                label-align-sm="left"
                label-cols-sm="12"
                label-size=""
            >
                <b-card no-body>
                    <b-tabs pills card>
                        <b-tab :title="locale.locale"
                               :active="locale.locale === $i18n.locale"
                               v-for="locale in data.locale" style="background: white">
                            <FormStateLocale
                                :locale="locale.locale"
                                :record.sync="setRecordLocale[locale.locale]"
                            ></FormStateLocale>
                        </b-tab>
                    </b-tabs>
                </b-card>
            </b-form-group>
            <!--  -->
        </b-form>
    </div>
</template>

<script>
import FormStateLocale from "./FormStateLocale";
import utilsGeneral from "../../../utils/utilsGeneral";
// @group Form.REGISTER
export default {
    name: "FormStates",
    components: {FormStateLocale},
    props: {
        form: {
            valid: false,
            record: {},
        },
        data: {},
        modalBus: Object,
    },
    data() {
        return {
            valid: null,
            record: {
                id: 0,
                active: false,
                order: 0,
                continent_id: null,
                currency_id: null,
                meta: {
                    name: '',
                    name_full: ''
                },
                content: {
                    code: '',
                    domain: '',
                    mark_2: '',
                    mark_3: '',
                    eu_member: false
                },
                locale: {},
            },
            continents: [],
            currencies: [],
        }
    },
    mounted() {
        this.modalBus.$on('submit', this.submit);
        // <-- set foreign data -->
        this.setContinents();
        this.setCurrencies();
        // <--  -->
        if (this.form.record != null) {
            this.$set(this, 'record', this.form.record);
        }
    },
    methods: {
        // <-- set selects data -->
        setContinents() {
            let continents = [
                {value: null, text: '/', disabled: false},
            ];
            let meta = false;
            let locale = {};
            this.data.continents.forEach((continent, index) => {
                meta = false;
                locale = continent.locale[this.$i18n.locale];
                // <-- if missing locale data -->
                if (locale === undefined) {
                    meta = true;
                    locale = continent.meta;
                }
                continents.push(
                    {
                        value: continent.id,
                        text: locale.name + (meta ? this.$t('form.meta.message', {msg: this.$t('form.name').toLowerCase()}) : ''),
                        disabled: false
                    }
                );
            });
            // <--  -->
            this.$set(this, 'continents', continents);
        },
        setCurrencies() {
            let currencies = [
                {value: null, text: '/', disabled: false},
            ];
            let meta = false;
            let locale = {};
            this.data.currencies.forEach((currency, index) => {
                meta = false;
                locale = currency.locale[this.$i18n.locale];
                // <-- if missing locale data -->
                if (locale === undefined) {
                    meta = true;
                    locale = currency.meta;
                }
                // <--  -->
                currencies.push(
                    {
                        value: currency.id,
                        text: locale.name + ' (' + currency.content.sign + ')' + (meta ? this.$t('form.meta.message', {msg: this.$t('form.name').toLowerCase()}) : ''),
                        disabled: false
                    }
                );
            });
            // <--  -->
            this.$set(this, 'currencies', currencies);
        },
        // <-- form -->
        submit() {
            if (this.$refs.form === undefined) {
                return;
            }
            this.valid = this.$refs.form.checkValidity();

            this.$set(this.form, 'record', this.record);
            this.$set(this.form, 'valid', this.valid);
        }
    },
    computed: {
        activeText: {
            get() {
                return !this.record.active || this.record.active === 'false' || this.record.active === undefined
                    ? this.$t('form.inactive')
                    : this.$t('form.active');
            }
        },
        setRecordLocale() {
            this.data.locale.forEach((value, index) => {
                // <-- add missing locale record -->
                if (Object.keys(this.record.locale).indexOf(value.locale) === -1) {
                    this.$set(this.record.locale, value.locale, {
                        name: '',
                        name_full: '',
                        notes: ''
                    });
                }
            });
            return this.record.locale;
        },
        valid_continent() {
            if (this.valid === null) {
                return null;
            }
            return this.record.continent_id > 0 ? null : false;
        },
        valid_currency() {
            if (this.valid === null) {
                return null;
            }
            return this.record.currency_id > 0 ? null : false;
        },
        // <-- meta -->
        valid_metaName() {
            if (this.valid === null) {
                return null;
            }
            return this.record.meta.name.length > 0 ? null : false;
        },
        valid_metaNameFull() {
            if (this.valid === null) {
                return null;
            }
            return this.record.meta.name_full.length > 0 ? null : false;
        },
    },
    filters: {},
    watch: {}
}
</script>

<style scoped>
.group-title {
    /*color: gray;*/
}
</style>
